import { render, staticRenderFns } from "./SofomModule.vue?vue&type=template&id=362157db&scoped=true&"
import script from "./SofomModule.vue?vue&type=script&lang=js&"
export * from "./SofomModule.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "362157db",
  null
  
)

export default component.exports