<template>
    <v-container fluid class="fill-height text-center mt-0 pt-0">
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-row>
                <v-col cols="12" class="align-center justify-center">
                    <v-card :loading="loadingData">
                        <v-form ref="form" v-model="valid" lazy-validation action="/admin/wireframe">
                            <v-toolbar flat class="primary align-center">
                                <v-toolbar-title class="text-subtitle-1 text-md-h5">
                                    <span class="headline white--text">{{ formTitle }}</span>
                                </v-toolbar-title>
                                <v-divider
                                    class="mx-4 white"
                                    inset
                                    vertical>
                                </v-divider>
                                <v-spacer></v-spacer>
                                <v-btn :small="$vuetify.breakpoint.smAndDown"
                                       color="blue darken-1"
                                       dark
                                       elevation="0"
                                       class="mb-0"
                                       @click="saveData">
                                    Guardar
                                </v-btn>
                            </v-toolbar>
                            <v-container fluid class="text-center">
                                <v-row class="mt-0">
                                    <v-col cols="12">
                                        <v-text-field
                                            dense
                                            v-model="editedItem.title"
                                            label="Título"
                                            :rules="textRules"
                                            required>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row no-gutters align="center" justify="center">
                                            <v-img
                                                :src="imagePreview != null ? imagePreview : require('@/assets/images/no-image.jpg')"
                                                :lazy-src="require('@/assets/images/no-image.jpg')"
                                                max-height="250"
                                                max-width="auto"
                                                :aspect-ratio="1"
                                                class="white--text align-center justify-center elevation-2">
                                            </v-img>
                                        </v-row>
                                        <v-row no-gutters align="center" justify="center">
                                            <v-file-input
                                                v-model="imageFile"
                                                accept="image/png, image/jpeg, image/bmp"
                                                show-size
                                                clearable
                                                :rules="imageRules"
                                                label="Cargar/Cambiar imágen"
                                                placeholder="Archivo de imágen"
                                                prepend-icon="mdi-camera"
                                                @change="changePreviewImage()">
                                            </v-file-input>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-container>
                            <tiptap-vuetify
                                v-model="editedItem.content"
                                :extensions="extensions"
                                placeholder="Escriba aquí su contenido..."
                                :toolbar-attributes="{ color: 'primary', dark: true }"
                                class="justify-start text-left"
                            />
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-snackbar
            v-model="snackBar"
            :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs"
                       icon
                       @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import sofomPageService from '@/providers/SofomPageService';
    // import the component and the necessary extensions
    import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList,
        Image, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify';
    // import TextAlign from '@tiptap/extension-text-align';

    export default {
        name: "SofomModuleComponent",
        // specify TiptapVuetify component in "components"
        components: {
            TiptapVuetify
        },
        data: () => ({
            snackBar: false,
            snackText: '',
            focus: false,
            valid: true,
            loadingData: false,
            imageFile: null,
            imagePreview: null,
            editedIndex: -1,
            editedItem: {
                id: 0,
                title: "",
                image_url: "",
                content: `SOFOM`,
            },
            defaultItem: {
                id: 0,
                title: "",
                image_url: "",
                content: `SOFOM`,
            },
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio!'
            ],
            imageRules: [
                value => !value || value.size < 2000000 || 'El tamaño de la imagen no debe de sobrepasar los 2 MegaBytes!'
            ],
            extensions: [
                History,
/*                [
                    TextAlign.configure({
                        types: ['heading', 'paragraph'],
                    })
                ],*/
                /*[
                    Image,
                    {
                        options: {
                            imageSourcesOverride: true,
                            imageSources: [{ component: FileSelector, name: "upload image" }],
                        },
                    },
                ],*/
                Blockquote,
                Link,
                Image,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }],
                Bold,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            // starting editor's content
            content: `
                  <h1>Yay Headlines!</h1>
                  <p>All these <strong>cool tags</strong> are working now.</p>
                  <h1>Most basic use</h1>
                  <p>
                    You can use the necessary extensions.
                    The corresponding buttons are
                    <strong>
                      added automatically.
                    </strong>
                  </p>
                  <pre><code>&lt;tiptap-vuetify v-model="content" :extensions="extensions"/&gt;</code></pre>
                  <p></p>
                  <h2>Icons</h2>
                  <p>Avaliable icons groups:</p>
                  <ol>
                    <li>
                      <p>Material Design <em>Official</em></p>
                    </li>
                    <li>
                      <p>Font Awesome (FA)</p>
                    </li>
                    <li>
                      <p>Material Design Icons (MDI)</p>
                    </li>
                  </ol>
                  <p></p>
                  <blockquote>
                    <p>This package is awesome!</p>
                  </blockquote>
                  <p></p>
                `
        }),
        computed: {
            formTitle() {
                return "SOFOM";
            }
        },
        methods: {
            getSofom() {
                this.loadingData = true;
                sofomPageService.getViewData().then(record => {
                    if (record.value !== null) {
                        this.editedItem = record.value;
                        this.editedIndex = this.editedItem.id;
                        this.hasData = true;
                        if (this.editedItem.image_url !== null) {
                            this.imagePreview = this.editedItem.image_url;
                        } else {
                            this.imagePreview = null;
                        }
                        this.imageFile = null;
                    } else {
                        this.hasData = false;
                        this.editedItem = this.defaultItem;
                        // this.newItem();
                    }
                    this.loadingData = false;
                });
            },
            newItem() {
                this.editedIndex = -1;
                this.imagePreview = null;
                this.imageFile = null;
                this.valid = false;
            },
            saveData() {
                if (this.imagePreview == null) {
                    // console.log('El formulario no contiene un logo asociado!!');
                    this.messageError = 'El formulario no contiene una imagen asociada. Por favor agrege una!!';
                    this.dialogError = true;
                    return;
                }
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        sofomPageService.updateRecord(this.editedItem, this.imageFile).then(result => {
                            if (result.success) {
                                sofomPageService.getViewData();
                                this.getSofom();
                            }
                        });
                    } else {
                        // Agrega el registro por el metodo POST
                        sofomPageService.addRecord(this.editedItem, this.imageFile).then(result => {
                            if (result.success) {
                                sofomPageService.getViewData();
                                this.getSofom();
                            }
                        });
                    }
                    this.snackText = 'Datos del formulario guardado con éxito...';
                    this.snackBar = true;
                }
            },
            changePreviewImage() {
                if (this.imageFile) {
                    // console.log('Data imagen: ', this.imageFile, '\n');
                    if (this.imageFile.size > 2000000) {
                        // this.imagePreview = null;
                        return;
                    }
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                        // console.log('Preview imagen: ',this.imagePreview, '\n');
                    };
                    reader.readAsDataURL(this.imageFile);
                    // console.log('Imagen actualizada: ', this.imageFile.name);
                    this.snackText = 'Imágen de la sección cargada con éxito...';
                    this.snackBar = true;
                }
            }
        },
        mounted() {
            this.editedItem = this.defaultItem;
            this.getSofom();
        }
    }
</script>

<style scoped>

</style>