<template>
    <sofom-module-component/>
</template>

<script>
    import SofomModuleComponent from "@/components/admin/SofomModuleComponent";
    export default {
        name: "SofomModule",
        title: "SOFOM | Private",
        components: { SofomModuleComponent }
    }
</script>

<style scoped>

</style>